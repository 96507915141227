@import '../../../../../theme/variables.scss';

.dashboard-header {
    height: $dashboard-header-height;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    gap: 30px;

    .messages-button,
    .notifications-button {
        cursor: pointer;

        .anticon {
            font-size: 25px;
            color: $dark-color;
            cursor: pointer;
            transition: all 0.3s ease;

            @media (max-width: 1480px) {
                font-size: 20px;
            }
        }

        &:hover {
            .anticon {
                transform: scale(1.1);
            }
        }
    }

    .profile-button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: block;
            transition: all 0.3s ease;

            @media (max-width: 1480px) {
                width: 30px;
                height: 30px;
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

.profile-dropdown {
    .my-profile {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 4px 6px;
        padding-top: 6px;
        font-size: 15px;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: block;
            transition: all 0.3s ease;
        }
    }

    .menu-item {
        padding: 10px 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        .anticon {
            font-size: 22px;
        }
    }

    .logout {
        cursor: pointer;
    }
}