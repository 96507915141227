@import '../../../../theme/variables.scss';

.home {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    height: 82vh;

    .box {
        background-color: $background-color;
        width: 100%;
        border-radius: 20px;
        padding: 20px;

        h1 {
            font-size: 40px;
            margin: 0;
            color: $primary-color;
        }

        p {
            font-size: 16px;
            margin: 0;
            color: $primary-color;
            line-height: 1.5;
        }

        h2 {
            font-size: 20px;
            margin: 0;
            color: $primary-color;
        }

        h4 {
            font-size: 56px;
            margin: 0;
            color: $primary-color;
            margin-top: 10px;
        }

        @media (max-width: 1440px) {
            h1 {
                font-size: 36px;
            }

            p {
                font-size: 14px;
            }

            h2 {
                font-size: 18px;
            }

            h4 {
                font-size: 52px;
            }
        }

        @media (max-width: 1366px) {
            h1 {
                font-size: 32px;
            }

            p {
                font-size: 12px;
            }

            h2 {
                font-size: 16px;
            }

            h4 {
                font-size: 48px;
            }
        }

        @media (max-width: 1024px) {
            h1 {
                font-size: 28px;
            }

            p {
                font-size: 10px;
            }

            h2 {
                font-size: 14px;
            }

            h4 {
                font-size: 44px;
            }
        }
    }

    .left {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .top {
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            height: 100%;

            .bookings {
                height: 55vh;
                overflow-y: scroll;
                scrollbar-width: none;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                @media (max-width: 1440px) {
                    height: 50vh;
                }

                @media (max-width: 1366px) {
                    height: 48vh;
                }

                .booking {
                    background-color: #fff;
                    border-radius: 10px;
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    }

                    h5 {
                        font-size: 18px;
                        margin: 0;
                        color: $primary-color;
                    }

                    .ql-editor {
                        font-size: 14px;
                        margin: 0;
                        padding: 5px 0;
                        color: $primary-color;
                        line-height: 1.5;

                        p {
                            font-size: 14px;
                        }
                    }

                    h6 {
                        font-size: 12px;
                        margin: 5px 0;
                        font-weight: 400;
                        color: $gray-color;
                    }
                }
            }

            .requests::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .right {
        flex: 1;
        flex-direction: column;
        gap: 20px;
        height: 100%;
        display: flex;

        .box {
            height: 100%;
        }

        .top {
            flex: 3;

            .requests {
                height: 40vh;
                overflow-y: scroll;
                scrollbar-width: none;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;

                @media (max-width: 1440px) {
                    height: 35vh;
                }

                .request {
                    background-color: #fff;
                    border-radius: 10px;
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    }

                    h5 {
                        font-size: 18px;
                        margin: 0;
                        color: $primary-color;
                    }

                    .ql-editor {
                        font-size: 14px;
                        margin: 0;
                        padding: 5px 0;
                        color: $primary-color;
                        line-height: 1.5;

                        p {
                            font-size: 14px;
                        }
                    }

                    h6 {
                        font-size: 12px;
                        margin: 0;
                        font-weight: 400;
                        color: $gray-color;
                    }
                }
            }

            .requests::-webkit-scrollbar {
                display: none;
            }
        }

        .bottom {
            flex: 2;

            .notifications {
                height: 26vh;
                overflow-y: scroll;
                scrollbar-width: none;
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-top: 10px;



                .notification {
                    background-color: #fff;
                    border-radius: 10px;
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    }

                    h5 {
                        font-size: 18px;
                        margin: 0;
                        color: $primary-color;
                    }

                    .ql-editor {
                        font-size: 14px;
                        margin: 0;
                        padding: 5px 0;
                        color: $primary-color;
                        line-height: 1.5;

                        p {
                            font-size: 14px;
                        }
                    }

                    h6 {
                        font-size: 12px;
                        margin: 0;
                        font-weight: 400;
                        color: $gray-color;
                    }
                }
            }

            .requests::-webkit-scrollbar {
                display: none;
            }
        }
    }
}