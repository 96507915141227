.user_tour_itinery {
    .items_list_acc {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .ql-editor {
            padding: 0;
        }

        .ql-tooltip {
            display: none;
        }

        .item {
            padding: 15px;
            border-radius: 10px;
            background-color: #5c5c5c0b;

            h3 {
                margin: 0;
            }

            p {
                margin: 10px 0;
            }

            .ant-image {
                gap: 10px;
                margin: 10px 0;
                margin-right: 10px;
            }

            .body {
                display: flex;
                gap: 20px;

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .actions {
                        display: flex;
                        gap: 10px;
                    }
                }

                .images {
                    p {
                        margin-top: 15px;
                    }
                }

                .detsils {
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    background-color: #fff;

                    .room__details {
                        display: flex;
                        gap: 20px;
                    }

                    .day__plan {
                        margin-top: 10px;

                        .item_iti_dis {
                            display: flex;
                            gap: 20px;
                            margin-bottom: 10px;

                            img {
                                width: 150px;
                                height: 100px;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }

    }
}