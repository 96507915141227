@import '../../../../../../theme/variables.scss';

.user__activity__log {
    h2 {
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }

    .ant-list-items {
        overflow-y: scroll;
        max-height: 60vh;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: $light-gray-color;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        scrollbar-width: thin;
        scrollbar-color: $light-gray-color #fff;
    }

    .activity__log__item {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 5px;

        h2 {
            margin: 0;
            font-size: 14px;
        }

        p {
            margin: 0;
            font-size: 14px;
            color: $gray-color;
        }

        span {
            font-size: 12px;
            color: $gray-color;
        }
    }
}