.messages {
    .messages_body {
        margin-top: 10px;
        overflow-y: scroll;
        max-height: 76vh;
        width: 100%;
        overflow-x: hidden;

        // hide scrollbar
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        .ant-ribbon-wrapper {
            width: 99%;
        }

        .message {
            background-color: #f5f5f5;
            border-radius: 20px;
            padding: 2px 20px 15px 20px;
            margin-bottom: 10px;
        }
    }
}