.booking_details {
    .p_container {
        .red {
            color: red;
        }

        .green {
            color: rgb(62, 177, 0);
        }

        .yellow {
            color: #ff9e00;
        }
    }

    .destination_card {
        background-color: #f8f8f8;
    }
}