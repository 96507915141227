@import '../../../../theme/variables.scss';

.users {
    .users__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .search {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .search__results {
            h4 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
        }
    }
}

.certain-category-search-dropdown {
    .result-row {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }

        .result-row-info {
            margin-left: 10px;

            h3 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }

            p {
                font-size: 12px;
                margin: 0;
                color: #7e7e7e;
            }
        }
    }
}