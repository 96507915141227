$menu-slider-width: 220px;
$dashboard-header-height: 7vh;
$dashboard-content-min-height: 89vh;
$dashboard-footer-height: 5vh;

$navbar_padding: 0 60px;
$content_padding: 133px;
$content_padding_tablet: 50px;
$content_padding_mobile: 20px;

// colors 
$primary-color: #063D67;
$dark-color: #1E445B;
$primary-color-light: #67DCD3;
$light-1-color: #3A8C8C;
$light_2_color: #50A8B8;
$light-3-color: #c5eff3;
$light-4-color: #EFFCFD;
$light_5_color: #50b6bb;
$white-color: #FBFBFB;
$pure-white-color: #FFFFFF;
$black-color: #191919;
$light-gray-color: #D0D0D0;
$very-light-gray-color: #e2e2e2;
$gray-color: #515151;
$dark-gray-color: #272727;
$medium-gray-color: #B4B4B4;
$background-color: #F8F8F8;
$red_orange_color: #F45713;
$danger_color: #FF4D4F;
$success_color: #52C41A;