.show_calendar {
    .ant-picker-cell {
        border: 1px solid #f0f0f0;
    }

    .calendar {
        width: 700px;
    }

    .actions {
        display: flex;
        gap: 10px;
        margin-top: 0px;
    }

    .guideence {
        display: flex;
        gap: 20px;

        p {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
}