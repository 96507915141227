.booking_body {
    margin-top: 10px;
    overflow-y: scroll;
    max-height: 82vh;
    width: 100%;
    overflow-x: hidden;
}

.booking_body::-webkit-scrollbar {
    display: none;
}

.booking_body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}