@import '../../../../../../theme/variables.scss';

.component__container {
    overflow-y: scroll;
    max-height: 85vh;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: $light-gray-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    scrollbar-width: thin;
    scrollbar-color: $light-gray-color #fff;

    .container_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;

        h1 {
            margin: 0;
        }

        .container_header_actions {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .container_body {
        .partner_profile {
            display: flex;
            gap: 20px;
            align-items: center;

            .logo {
                img {
                    width: 300px;
                    border-radius: 10px;
                    object-fit: cover;
                }
            }

            .info {
                .report-person {
                    background-color: #efefefb3;
                    padding: 20px;
                    border-radius: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #efefef;
                    }

                    h3 {
                        margin: 0;
                        font-weight: 500;
                    }
                }

                .report {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    img {
                        width: 50px;
                    }

                    .report_info {
                        h3 {
                            margin: 0;
                            margin-bottom: 10px
                        }

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}