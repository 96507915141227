@import '../../../../../../theme/variables.scss';

.users-table {
    margin-top: 20px;

    .table {
        .ant-table {
            max-height: 550px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 12px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background: $light-gray-color;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            scrollbar-width: thin;
            scrollbar-color: $light-gray-color #fff;
        }
    }
}