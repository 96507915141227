.tour_bookings {
    margin-top: 10px;
    overflow-y: scroll;
    max-height: 80vh;
    width: 100%;
    overflow-x: hidden;

    /* Hide scrollbar for Chrome, Safari and Opera */
    .example::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .example {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}