.custom_tour_chat {
    h1 {
        margin: 0;
    }

    .chat_body {
        .chat_messages {
            height: 60vh;
            overflow-y: auto;
            padding: 10px;
            background-color: #eeeeeea1;
            border-radius: 10px;
            margin-top: 10px;
            display: flex;
            flex-direction: column;

            .chat_message {
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.049);
                width: 70%;

                .ql-editor {
                    padding: 0;
                    margin-top: 10px;
                }
            }

            .admin_chat_message {
                align-self: end;
            }

            .user_chat_message {
                align-self: start;
            }

            .chat_message_sender {
                display: flex;
                align-items: center;
                gap: 10px;

                h4,
                p {
                    margin: 0;
                }
            }

            .ant-btn {
                padding: 0;
            }
        }

        .chat_message_input {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            border: 1px solid #cccccc8a;
            border-radius: 10px;
            padding: 10px;

            .ant-form-item {
                margin-bottom: 0;
            }

            .quill {
                width: 100%;
            }
        }
    }
}