@import '../../../../../theme/variables.scss';

.support_ticket_single {
    margin-top: 10px;
    overflow-y: scroll;
    max-height: 82vh;
    width: 100%;
    overflow-x: hidden;

    h1,
    p,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding: 0;
        margin: 0;
    }

    .ql-editor {
        padding: 0;
        margin: 0;
    }

    .top_padding_container {
        .loading_container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40vh;
        }

        .ticket_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .ticket_header__left {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
        }

        .meta {
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 5px 0;
        }

        p {
            margin: 10px 0 0 0;
            font-size: 15px;
        }

        a {
            color: $light-1-color;
            font-size: 15px;
        }

        .attachments {
            margin-top: 15px;
        }
    }

    .messages {
        h2 {
            font-size: 20px;
            font-weight: 600;
            margin: 0;
            padding: 0;
            color: $primary-color;

            @media (max-width: 768px) {
                font-size: 26px;
            }
        }

        .no_messages {
            border: 1px solid $light-gray-color;
            margin-top: 20px;
            border-radius: 10px;
            padding: 40px 0;
        }

        .messages_container {
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;

            .message_card {
                background-color: #f5f5f5;
                padding: 15px 20px;
                border-radius: 10px;

                .message_card__header {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .sender {
                        width: 40px;
                        height: 40px;
                        border-radius: 50px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50px;
                        }
                    }

                    .sender_details {
                        p {
                            margin: 0;
                            font-size: 13px;
                        }

                        .author {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .your_reply {
            margin-top: 20px;
            background-color: #fff;
            padding: 20px;
            border-radius: 10px;

            .ant-form {
                margin-top: 10px;
            }

        }
    }

}

.support_ticket_single::-webkit-scrollbar {
    display: none;
}

.support_ticket_single {
    -ms-overflow-style: none;
    scrollbar-width: none;
}