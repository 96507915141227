@import '../../../../../theme/variables.scss';

.sidebar {
    background-color: #fff !important;
    max-width: $menu-slider-width !important;
    min-width: $menu-slider-width !important;
    width: $menu-slider-width !important;
    height: 100ch;

    .menu {
        -ms-overflow-style: none;
        scrollbar-width: none;

        .ant-menu-item:active {
            background-color: $light-3-color !important;
        }

        .ant-menu-item-selected {
            background-color: $light-3-color;

            .ant-menu-title-content {
                color: $primary-color;
                font-weight: 700 !important;
            }

            .anticon {
                color: $primary-color;
            }
        }

        .ant-menu-submenu-selected {
            .ant-menu-submenu-title {
                color: $primary-color;
                font-weight: 700 !important;
                background-color: $light-4-color;
            }
        }
    }

    .logo {
        height: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 80%;

            @media (max-width: 1480px) {
                width: 65%;
            }
        }
    }
}