@import '../../../../../theme/variables.scss';

.destinations {
    .destinations__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .left {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }

    .destinations__list {
        margin-top: 20px;
        overflow-y: scroll;
        max-height: calc($dashboard-content-min-height - 170px);
        width: 100%;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: $light-gray-color;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        scrollbar-width: thin;
        scrollbar-color: $light-gray-color #fff;

        .destination__item {
            cursor: pointer;
            display: flex;
            gap: 20px;
            align-items: start;

            &:hover {
                background: #c1c1c112;
            }

            .left {
                display: block;
                flex: 1;

                img {
                    width: 100%;
                }
            }

            .right {
                display: block;
                flex: 3;

                .destination__item__header {
                    display: flex;
                    gap: 20px;
                }

                h3 {
                    margin: 0;
                    font-size: 24px;
                }

                .ql-editor {
                    margin: 10px 0;
                    padding: 0;
                }

                .ql-tooltip {
                    display: none;
                }
            }
        }
    }
}

.certain-category-search-dropdown-destinations {
    .result-row {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        .result-row-info {
            h3 {
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }

            p {
                font-size: 12px;
                margin: 0;
                color: #7e7e7e;
            }
        }
    }
}