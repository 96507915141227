.user_toru_accommodations {
    .booking__accommodations__card {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 15px 20px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .booking__loading_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
    }

    .booking__accommodations {
        .booking__accommodations__card {
            background-color: #f4f4f4;
            margin: 10px 0;

            .booking__accommodations__card__header {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .booking__accommodations__card__header__actions {
                    display: flex;
                    gap: 10px;
                }
            }

            h4,
            p {
                margin: 0;
            }

            .ql-editor {
                padding: 0;
            }
        }
    }
}