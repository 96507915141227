@import '../../../../../../theme/variables.scss';

.user__details {
    margin-top: 10px;
    overflow-y: scroll;
    max-height: 70vh;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: $light-gray-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    scrollbar-width: thin;
    scrollbar-color: $light-gray-color #fff;

    .user__details__header {
        display: flex;
        align-items: center;

        .ant-badge {
            img {
                width: 150px;
                height: 150px;
                border-radius: 50%;
            }

            sup {
                margin-top: 15px;
                font-size: 14px;
                margin-right: 5px;
                height: 25px;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
            }
        }

        .user__details__header__info {
            margin-left: 30px;

            h3 {
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }

            p {
                font-size: 14px;
                margin: 0;
                color: $gray-color;
                margin-bottom: 10px;
            }
        }
    }

    .user__details__body {
        display: flex;
        gap: 30px;
        margin: 0;

        h3 {
            color: $primary-color;
            margin-top: 0;
        }
    }

    .user__confedentials__body {
        h3 {
            color: $primary-color;
            margin-top: 0;
        }

        .user__confedentials__body__locked {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .user__confedentials__body__unlocked {
            display: flex;
            gap: 30px;
        }
    }
}