@import '../../../../../theme/variables.scss';

.user {
    .user__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user__header__left {
            display: flex;
            align-items: center;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .user__name {
                margin-left: 10px;
                font-weight: 400;
                color: $gray-color;
                font-size: 20px;
                margin-top: 20px;
            }

            .user_name {
                font-weight: 400;
                color: $gray-color;
                font-size: 25px;
                margin-top: 20px;
            }
        }

        .user__actions {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}