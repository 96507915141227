@import '../../../../../theme/variables.scss';

.blog_main {
    max-height: 86vh;
    overflow-y: scroll;

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }

    .blog_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .users__header__actions {
            display: flex;
            align-items: center;
            gap: 10px;

            .ant-form {
                border: 1px solid #e7e7e7;
                padding: 5px 10px;
                border-radius: 10px;
            }
        }
    }

    .blog_item {
        .blog_header {
            display: flex;
            justify-content: space-between;

            .blog_meta {
                .author {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .author_image {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        border: 1px solid $primary-color-light;
                        padding: 2px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                    }

                    .author_name {
                        h3 {
                            margin: 0;
                            font-size: 18px;
                            color: $dark-color;
                        }

                        p {
                            margin: 0;
                            font-size: 12px;
                            color: $gray-color;
                        }
                    }
                }
            }

            .actions {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }

        .blog_short {
            margin-top: 20px;

            .tags {
                margin: 20px 0;

                .ant-tag {
                    font-size: 14px;
                    padding: 5px 15px;
                }
            }
        }

        .tagged_destinations {
            .ant-tag {
                cursor: pointer;
                transition: all 0.3s;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .blog_images {
            margin-top: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            @media (max-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            img {
                width: 100%;
                height: 300px;
                object-fit: cover;
                border-radius: 10px;

                @media (max-width: 768px) {
                    height: 200px;
                }
            }
        }
    }

    .packages {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .package_item {
            display: flex;
            gap: 20px;
            align-items: center;
            background-color: $white-color;
            border: 1px solid #e7e7e7;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            }

            .package_image {
                width: 200px;
                height: 100px;
                border-radius: 10px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .destination__card__content {
                h2 {
                    margin: 0;
                    font-size: 18px;
                    color: $dark-color;
                    margin-bottom: 10px;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    color: $gray-color;
                }
            }

            .package_actions {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
    }
}