@import '../../../../../../../theme/variables.scss';

.my_tour_booking_tour_details {
    .booking__loading_container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30vh;
    }

    .image_gallery {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        height: 400px;
        position: relative;

        @media (max-width: 1366px) {
            height: 300px;
        }

        @media (max-width: 1024px) {
            height: 200px;
        }

        .total {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.6);
            color: $white-color;
            padding: 5px 10px;
            border-radius: 5px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 10px;
            cursor: pointer;
        }

        .left {
            width: 70%;
        }

        .right {
            width: 30%;

            .images_container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                height: 100%;

                span {
                    height: 50%;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            .more_images {
                display: none;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 5px;
            margin-top: 10px;
            height: auto;

            .total {
                bottom: 5px;
                right: 5px;
                font-size: 12px;
            }

            img {
                border-radius: 5px;
            }

            .left {
                width: 100%;
            }

            .right {
                width: 100%;

                .images_container {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    span {
                        height: 70px;
                        width: 100%;

                        img {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .sec__2 {
        .slider {
            margin: 15px 0 20px 0;

            .react-multiple-carousel__arrow {
                z-index: 10 !important;
            }

            .custom-dot-list-style {
                display: none;
            }

            .destination_card {
                margin: 0 5px;
                border: none;
            }

            .card_image {
                height: 170px;
                border-radius: 10px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            .card_body {
                padding: 0;
            }

            .card_title {
                margin: 0;
                margin-top: 10px;
                font-size: 18px;
                font-weight: 600;
                color: $dark-color;
                padding: 0;
            }
        }

        .route {
            margin-top: 15px;
            width: 100%;
            height: 400px;
            background-color: rgba(226, 226, 226, 0.662);
            border-radius: 10px;

            iframe {
                width: 100%;
                height: 100%;
                border: none;
                border-radius: 10px;
            }
        }
    }

    .sec__3 {
        .itinerary {
            margin-top: 15px;

            .itinerary_step {
                .ant-steps-icon-dot {
                    background: none !important;
                    border: 2px solid $primary-color-light;
                    width: 15px;
                    height: 15px;
                }

                .ant-steps-item-tail {
                    top: 15px;
                    left: 3px;
                }

                .ant-steps-item-container {

                    .ant-steps-item-content {
                        background-color: $background-color;
                        margin-bottom: 10px;
                        border-radius: 10px;

                        .ant-steps-item-title {
                            width: 100%;
                            padding: 0 10px;
                        }

                        .ant-collapse-header {
                            padding: 12px 16px 0 16px;

                            .ant-collapse-header-text {
                                span {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;

                                    h2 {
                                        font-size: 16px;
                                        margin: 0;
                                        font-weight: 700;
                                        color: $gray-color;
                                    }

                                    p {
                                        font-size: 16px;
                                        margin: 0;
                                        color: $primary-color;
                                        font-weight: 600;
                                    }

                                    @media (max-width: 768px) {
                                        h2 {
                                            font-size: 14px;
                                        }

                                        p {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }

                        .itinerary_collapse {
                            h2 {
                                font-size: 20px;
                                font-weight: 700;
                                color: $gray-color;
                                margin: 0;
                            }

                            h3 {
                                font-size: 18px;
                                font-weight: 600;
                                color: $primary-color;
                                margin: 10px 0;
                                cursor: pointer;

                                &:hover {
                                    color: $light-1-color;
                                }
                            }

                            .day_description {
                                margin: 0;
                            }

                            h4 {
                                font-size: 18px;
                                font-weight: 600;
                                color: $primary-color;
                                margin: 0 0 10px 0;
                            }

                            @media (max-width: 768px) {
                                h2 {
                                    font-size: 18px;
                                }

                                h3 {
                                    font-size: 16px;
                                }

                                h4 {
                                    font-size: 16px;
                                }
                            }

                            .main__destination {
                                display: flex;
                                margin-top: 10px;
                                gap: 20px;
                                align-items: start;

                                .left {
                                    order: 1;

                                    img {
                                        width: 300px;
                                        height: 160px;
                                        object-fit: cover;
                                        border-radius: 10px;
                                    }
                                }

                                .right {
                                    order: 2;

                                    .ql-editor {
                                        padding: 0;
                                        background-color: none;
                                        all: unset;

                                        p {
                                            font-size: 14px;
                                            line-height: 1.5;
                                            color: $dark-color;

                                            @media (max-width: 768px) {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }

                                @media (max-width: 576px) {
                                    flex-direction: column;
                                    gap: 10px;

                                    .left {
                                        order: 2;

                                        img {
                                            width: 100%;
                                            height: 150px;
                                        }
                                    }

                                    .right {
                                        order: 1;
                                    }
                                }
                            }

                            .experience {
                                .note {
                                    margin-top: 10px;

                                    span {
                                        color: $red_orange_color;
                                        font-weight: 600;
                                    }
                                }

                                .itemss {
                                    margin-top: 15px;

                                    .item_iti_dis {
                                        display: flex;
                                        gap: 20px;

                                        .left {
                                            order: 1;

                                            img {
                                                width: 300px;
                                                height: 160px;
                                                object-fit: cover;
                                                border-radius: 10px;
                                            }
                                        }

                                        .right {
                                            order: 2;

                                            p {
                                                margin: 5px 0;
                                            }

                                            .ql-editor {
                                                padding: 0;
                                                background-color: none;
                                                all: unset;

                                                p {
                                                    font-size: 14px;
                                                    line-height: 1.5;
                                                    color: $dark-color;

                                                    @media (max-width: 768px) {
                                                        font-size: 14px;
                                                    }
                                                }
                                            }
                                        }

                                        @media (max-width: 576px) {
                                            flex-direction: column;
                                            gap: 10px;

                                            .left {
                                                order: 2;

                                                img {
                                                    width: 100%;
                                                    height: 150px;
                                                }
                                            }

                                            .right {
                                                order: 1;

                                                p {
                                                    margin: 5px 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}