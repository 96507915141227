.user_tour_itinery {

    .not_published {
        color: #e20000;
        padding: 10px;
        border: 1px solid #e20000;
        margin-top: 10px;
        border-radius: 10px;

        h2 {
            font-size: 16px;
            margin: 0;
        }

        p {
            margin: 0;
        }
    }

    .main_actions {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        align-items: center;
    }

    .itinery__status {
        margin-top: 10px;

        h3,
        p,
        h4 {
            margin: 0;
        }

        h3 {
            margin: 10px 0;
        }

        .change_request {
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            border: 1px solid #dedede;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            margin-bottom: 10px;

            h4 {
                font-size: 17px;
            }
        }

        .user_agreed {
            position: relative;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000000af;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                border-radius: 10px;
                flex-direction: column;
                color: #fff;
            }
        }
    }

    .items_list_acc {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .ql-editor {
            padding: 0;
        }

        .ql-tooltip {
            display: none;
        }

        .item {
            padding: 15px;
            border-radius: 10px;
            background-color: #5c5c5c0b;

            h3 {
                margin: 0;
            }

            p {
                margin: 10px 0;
            }

            .ant-image {
                gap: 10px;
                margin: 10px 0;
                margin-right: 10px;
            }

            .body {
                display: flex;
                gap: 20px;

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .actions {
                        display: flex;
                        gap: 10px;
                    }
                }

                .images {
                    p {
                        margin-top: 15px;
                    }
                }

                .detsils {
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    background-color: #fff;

                    .room__details {
                        display: flex;
                        gap: 20px;
                    }

                    .day__plan {
                        margin-top: 10px;

                        .item_iti_dis {
                            display: flex;
                            gap: 20px;
                            margin-bottom: 10px;

                            img {
                                width: 150px;
                                height: 100px;
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
        }

    }
}