@import '../../../../../../theme/variables.scss';

.destination {
    overflow-y: scroll;
    max-height: 85vh;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: $light-gray-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    scrollbar-width: thin;
    scrollbar-color: $light-gray-color #fff;

    .destination__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .destination__header__actions {
            display: flex;
            gap: 10px;
        }
    }

    .destination__content_images {
        display: flex;
        gap: 20px;

        .destination__content__image {
            flex: 1;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }

        .destination__content__galery {
            flex: 2;

            .destination__content__galery__image {
                width: 100%;
                border-radius: 5px;
                padding: 2px;
            }
        }
    }

    .destination__content_videos {
        .destination__content__videos {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            video {
                width: 250px;
                height: 150px;
                border-radius: 10px;
            }
        }
    }

    .destination__content_details {
        .destination__content__details {
            display: flex;
            gap: 30px;
        }
    }

    .destination__content_description {
        .ql-editor {
            padding: 0;
        }

        .ql-tooltip {
            display: none;
        }
    }
}