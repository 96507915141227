@import '../../../../../../../theme/variables.scss';

.traver_details {
    h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $primary-color;
    }

    h2 {
        font-size: 20px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 10px;
        color: $gray-color;
    }

    h4 {
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        color: $primary-color;
        margin-top: 10px;
    }

    .travellers_info {
        margin-top: 20px;

        .travellers_cont {
            margin-bottom: 20px;

            .traveler {
                margin-bottom: 10px;
            }
        }
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}