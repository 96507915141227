@import '../../../../../../theme/variables.scss';

.add__destination {
    overflow-y: scroll;
    max-height: 84vh;
    width: 100%;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: $light-gray-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    scrollbar-width: thin;
    scrollbar-color: $light-gray-color #fff;
}