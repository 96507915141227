@import '../../../../../../theme/variables.scss';

.edit__user {
    .ant-form-item {
        margin-bottom: 10px;
    }

    h1 {
        margin: 15px 0 0 0;
    }

    h2 {
        margin: 0 0 15px 0;
    }

    .edit__user__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .edit__user__content {
        overflow-y: scroll;
        max-height: 70vh;
        width: 100%;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: $light-gray-color;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        scrollbar-width: thin;
        scrollbar-color: $light-gray-color #fff;

        .edit__user__content__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0;
            padding: 0;

            .edit__user__header__actions {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .user__profile__pic {
            margin-bottom: 15px;
        }

    }
}