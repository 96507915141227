@import '../../../../../../theme/variables.scss';

.destination {
    overflow-y: scroll;
    max-height: 85vh;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #fff;
    }

    &::-webkit-scrollbar-thumb {
        background: $light-gray-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    scrollbar-width: thin;
    scrollbar-color: $light-gray-color #fff;

    .destination__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .destination__header__actions {
            display: flex;
            gap: 10px;
        }
    }

    .destination__content_images {
        display: flex;
        gap: 20px;

        .destination__content__image {
            flex: 1;

            img {
                width: 100%;
                border-radius: 10px;
            }
        }

        .destination__content__galery {
            flex: 2;

            .destination__content__galery__image {
                width: 100%;
                border-radius: 5px;
                padding: 2px;
            }
        }
    }

    .destination__content_videos {
        .destination__content__videos {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            video {
                width: 250px;
                height: 150px;
                border-radius: 10px;
            }
        }
    }

    .hotel__content_details {
        .ql-tooltip {
            display: none;
        }

        h2 {
            margin-bottom: 2px;
        }

        .destination__content__details {
            display: flex;
            gap: 50px;

            .sec {
                width: 500px;

                p {
                    margin-bottom: 20px;
                    font-size: 15px;
                    display: flex;

                    strong {
                        width: 140px;
                        display: block;
                    }
                }
            }
        }
    }

    .destination__content_description {
        .ql-editor {
            padding: 0;
        }

        .ql-tooltip {
            display: none;
        }
    }

    .content {
        h2 {
            margin: 5px 0;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .actions {
                display: flex;
                gap: 10px;
            }
        }

        .items_list_acc {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 6px;

            .item {
                padding: 15px;
                border-radius: 10px;
                background-color: #5c5c5c0b;

                h3 {
                    margin: 0;
                }

                p {
                    margin: 10px 0;
                }

                .body {
                    display: flex;
                    gap: 20px;

                    .images {
                        p {
                            margin-top: 15px;
                        }
                    }

                    .detsils {
                        width: 100%;
                        padding: 10px;
                        border-radius: 10px;
                        background-color: #fff;

                        .room__details {
                            display: flex;
                            gap: 20px;
                        }
                    }
                }
            }

        }

    }

    .hotel__content_details {
        .ant-collapse-content-box {
            padding: 0 !important;
            margin: 0 !important;
        }

        .ant-collapse-header {
            padding: 0;
            display: flex;
            align-items: center;

            span {
                font-size: 21px;
                font-weight: 700;
            }
        }

        .ql-tooltip {
            display: none;
        }

        .ql-editor {
            padding: 0;
        }
    }
}